<template>
  <v-row class="d-flex justify-start mt-0">
    <!-- FILTROS DE BUSQUEDA -->
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form @submit.prevent="buscar()">
            <v-row class="d-flex align-center">
              <v-col cols="6" sm="3" md="2" class="pt-1">
                Empresa
                <v-autocomplete
                  v-model="empresa"
                  item-value="id"
                  item-text="nombre"
                  :items="familias"
                  hide-details
                  outlined
                  dense
                  @change="selectSucursal(); selectFinanciera()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="pt-1">
                Sucursal
                <v-autocomplete
                  v-model="sucursal"
                  item-text="provincia"
                  :items="sucursales.filter(suc => suc.familia_id == empresa)"
                  return-object
                  hide-details
                  outlined
                  dense
                  @change="limpiar()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="pt-1">
                Tipo
                <v-autocomplete
                  v-model="tipo"
                  item-text="nombre"
                  :items="tipos"
                  return-object
                  hide-details
                  outlined
                  dense
                  @change="selectFinanciera()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="pt-1">
                {{ tipo.codigo == 'GR' ? 'Grupo/Orden' : 'Preventa' }}
                <v-text-field-simplemask
                  v-model.trim="preventa"
                  v-bind:properties="{
                    prefix: tipo.codigo,
                    readonly: Object.keys(tipo).length == 0,
                    filled: Object.keys(tipo).length == 0,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                  }"
                  v-bind:options="{
                    inputMask: tipo.codigo == 'GR' ? '####-###' : '#####/##',
                    outputMask: tipo.codigo == 'GR' ? '####-###' : '#####/##',
                    applyAfter: false,
                    alphanumeric: true,
                    empty: ''
                  }"
                  @change="limpiar()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2" class="pt-1 pr-0">
                Financiera
                <v-autocomplete
                  v-model="financiera"
                  item-text="abreviacion"
                  :items="financieras.filter(fin => fin.empresas.find(ef => ef == empresa) && fin.tipos.find(tf => tf == tipo.id))"
                  return-object
                  hide-details
                  outlined
                  dense
                  @change="limpiar()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="d-flex justify-end pb-sm-0">
                <v-btn
                  color="info"
                  type="submit"
                >
                  <v-icon small left>fas fa-search</v-icon>
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- DATOS DEL CLIENTE -->
    <v-col cols="12" class="pt-0">
      <v-card :disabled="!existe">
        <v-card-title class="pt-1">
          Cliente
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form ref="formCliente" @submit.prevent="buscar()">
          <v-row>
            <v-col cols="12" sm="6" md="4" xl="3" class="py-0">
              <v-text-field
                v-model="datos.cliente"
                label="Nombre"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="datos.cuil"
                label="CUIL"
                :readonly="datos_copy.cuil !== null"
                :filled="datos_copy.cuil !== null"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.nacionalidad"
                label="Nacionalidad"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model="datos.ocupacion"
                label="Ocupación"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3"  xl="2" class="py-0">
              <v-text-field
                v-model="datos.calle"
                label="Calle"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="1" class="py-0">
              <v-text-field
                v-model="datos.numero"
                label="Nº"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="1" class="py-0">
              <v-text-field
                v-model="datos.piso"
                label="Piso"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="1" class="py-0">
              <v-text-field
                v-model="datos.dep"
                label="Dep."
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.cp"
                label="Cod. Postal"
                :readonly="datos_copy.cp !== null"
                :filled="datos_copy.cp !== null"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model="datos.localidad"
                label="Localidad/Barrio"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="datos.departamento"
                label="Departamento"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="datos.provincia"
                label="Provincia"
                :readonly="datos_copy.provincia !== null"
                :filled="datos_copy.provincia !== null"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-autocomplete
                v-model="datos.tipo_documento"
                label="Tipo Documento"
                item-text="nombre"
                item-value="nombre"
                :items="tipos_doc"
                :readonly="datos_copy.preventa ? (datos_copy.tipo_id != 2 && datos_copy.tipo_documento == null ? false : true) : true"
                :filled="datos_copy.preventa ? (datos_copy.tipo_id != 2 && datos_copy.tipo_documento == null ? false : true) : true"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.dni"
                label="Nº Documento"
                :readonly="datos_copy.dni !== null"
                :filled="datos_copy.dni !== null"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
             <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.autoridad"
                label="Autoridad"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-autocomplete
                v-model="datos.sexo"
                label="Sexo"
                item-text="nombre"
                item-value="abreviacion"
                :items="tipos_sexo"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <FechaPicker
                v-model="datos.nacimiento"
                label="Nacimiento"
                :disabled="datos_copy.nacimiento !== null"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-if="datos_copy.estado_civil !== null"
                v-model="datos.estado_civil"
                label="Estado Civil"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
              <v-autocomplete
                v-else
                v-model="datos.estado_civil"
                label="Estado Civil"
                item-text="nombre"
                item-value="nombre"
                :items="tipos_est_civil"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.nupcia"
                label="Nupcia"
                type="number"
                :readonly="datos.estado_civil != 'Casado'"
                :filled="datos.estado_civil != 'Casado'"
                :rules="datos.estado_civil == 'Casado' ? [$store.state.rules.required] : []"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="3" md="4" xl="3" class="py-0">
              <v-text-field
                v-model="datos.conyuge"
                label="Conyuge"
                :readonly="datos_copy.conyuge !== null || datos.estado_civil != 'Casado'"
                :filled="datos_copy.conyuge !== null || datos.estado_civil != 'Casado'"
                :rules="datos.estado_civil == 'Casado' ? [$store.state.rules.required] : []"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- DATOS DEL AUTO -->
    <v-col cols="12" class="pt-0">
      <v-card :disabled="!existe">
        <v-card-title class="pt-1">
          Vehículo
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form ref="formVehiculo" @submit.prevent="buscar()">
          <v-row>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.patente"
                label="Patente"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.marca"
                label="Marca"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model.trim="datos.tipo"
                label="Tipo"
                :readonly="datos_copy.tipo != null"
                :filled="datos_copy.tipo != null"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="4" xl="2" class="py-0">
              <v-text-field
                v-model="datos.modelo"
                label="Modelo"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="5" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.marca_motor"
                label="Marca Motor"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="7" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model="datos.motor"
                label="Nº Motor"
                :readonly="datos_copy.unidad_id != null || datos_copy.tipo_id == 3"
                :filled="datos_copy.unidad_id != null || datos_copy.tipo_id == 3"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="5" sm="3" md="2" xl="1" class="py-0">
              <v-text-field
                v-model="datos.marca_chasis"
                label="Marca Chasis"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="7" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model="datos.chasis"
                label="Nº Chasis"
                :readonly="datos_copy.unidad_id != null || datos_copy.tipo_id == 3"
                :filled="datos_copy.unidad_id != null || datos_copy.tipo_id == 3"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="1" class="py-0">
              <v-text-field
                v-model="datos.anio"
                label="Año"
                :readonly="datos_copy.unidad_id != null || datos_copy.tipo_id == 3"
                :filled="datos_copy.unidad_id != null || datos_copy.tipo_id == 3"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- FORMULARIO PRENDA -->
    <v-col
      v-if="selected.find(s => s == 3)"
      cols="12"
      class="pt-0"
    >
      <v-card>
        <v-card-title class="pt-1">
          Formulario prenda
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form ref="formPrenda" @submit.prevent="buscar()">
          <v-row>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <FechaPicker
                v-model="datos.fecha_prenda"
                label="Fecha del contrato"
              />
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2"
              cols="6" sm="3" md="2"
              class="py-0"
            >
              <v-text-field-money
                v-model.trim="datos.monto_prenda"
                label="Monto"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  outlined: true,
                  rules: [$store.state.rules.required],
                  'validate-on-blur': true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
                @blur="calcular_cuota()"
              />
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2"
              cols="6" sm="3" md="1"
              class="py-0"
            >
              <v-text-field
                v-model="datos.cuotas"
                label="Cuotas"
                type="number"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
                @blur="calcular_cuota()"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2"
              cols="6" sm="3" md="2"
              class="py-0"
            >
              <v-text-field-money
                v-model.trim="valor_cuota"
                label="Valor cuota"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  outlined: true,
                  readonly: true,
                  filled: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
              />
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2"
              cols="6" sm="3" md="2"
              class="py-0"
            >
              <FechaPicker
                v-model="datos.fecha_vencimiento"
                label="Fecha vencimiento"
              />
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2"
              cols="6" sm="3" md="2" xl="1"
              class="py-0"
            >
              <v-text-field
                v-model="datos.interes"
                label="Interes"
                type="number"
                suffix="%"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2 && datos.financiera.id != 3"
              cols="6" sm="3" md="2" xl="1"
              class="py-0"
            >
              <v-text-field
                v-model="datos.tasa"
                label="Tasa"
                type="number"
                suffix="%"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2 && datos.financiera.id != 3"
              cols="6" sm="3" md="2"
              class="py-0"
            >
              <v-text-field
                v-model="datos.costo_financiero_ci"
                label="C. Financiero (C/I)"
                type="number"
                suffix="%"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="datos.tipo_id != 2 && datos.financiera.id != 3"
              cols="6" sm="3" md="2"
              class="py-0"
            >
              <v-text-field
                v-model="datos.costo_financiero_si"
                label="C. Financiero (S/I)"
                type="number"
                suffix="%"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="datos.empresa == 1 ? false : (datos.tipo_id == 2 ? true : false)"
              cols="6" sm="3" md="2"
              class="py-0"
            >
              <v-text-field
                v-model="datos.celular"
                label="Tel. celular"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- (solo para citroen y santander) -->
            <v-col v-if="datos.empresa != 1 || datos.financiera.id == 3" cols="6" sm="3" xl="2" class="py-0">
              <v-autocomplete
                v-model="datos.uso"
                label="Uso del vehiculo"
                item-text="nombre"
                item-value="nombre"
                :items="usos.filter(uso => uso.inhabilitado != 1)"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              >
                <template v-slot:append-outer>
                  <v-btn
                    title="Agregar uso"
                    color="success"
                    small
                    icon
                    @click="dialog_uso = true"
                  >
                    <v-icon small>fas fa-plus</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
            <template v-if="datos.estado_civil == 'Casado'">
              <v-col v-if="datos.empresa == 1 && datos.financiera.id != 3" cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model="datos.nacionalidad_conyuge"
                  label="Nacionalidad conyuge"
                  :rules="[$store.state.rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-if="datos.financiera.id != 3"
                  v-model="datos.documento_conyuge"
                  label="Documento conyuge"
                  :rules="[$store.state.rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="datos.empresa == 1 && datos.financiera.id != 3" cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model="datos.edad_conyuge"
                  label="Edad conyuge"
                  type="number"
                  :rules="[$store.state.rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="datos.empresa != 1 && datos.financiera.id != 3" cols="12" class="py-0">
                <v-textarea
                  v-model="datos.referencia_ccc"
                  label="Referencia Cod. Civil y Com."
                  rows="3"
                  :rules="[$store.state.rules.required]"
                  validate-on-blur
                  auto-grow
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </template>
            <!-- GARANTE (solo para citroen plan de ahoro) -->
            <v-card
              v-if="datos.empresa != 1 && datos.tipo_id == 2"
              style="width:100%"
              class="mx-3 mb-4"
              outlined
            >
              <v-card-title class="pt-1" style="font-size: 18px">
                Datos del garante
              </v-card-title>
              <v-card-text class="py-1">
                <v-row>
                  <v-col cols="12" sm="6" md="4" xl="3" class="py-0">
                    <v-text-field
                      v-model="datos.garante"
                      label="Nombre"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field-simplemask
                      v-model="datos.cuil_garante"
                      label="CUIL"
                      v-bind:properties="{
                        rules: datos.garante ? [$store.state.rules.required] : [],
                        'validate-on-blur': true,
                        outlined: true,
                        dense: true
                      }"
                      v-bind:options="{
                        inputMask: '##-########-#',
                        outputMask: '##-########-#',
                        applyAfter: false,
                        alphanumeric: false,
                        empty: null
                      }"
                    />
                  </v-col>
                  <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
                    <v-autocomplete
                      v-model="datos.estado_civil_garante"
                      label="Estado Civil"
                      item-text="nombre"
                      item-value="nombre"
                      :items="tipos_est_civil"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="1" xl="1" class="py-0">
                    <v-text-field
                      v-model="datos.edad_garante"
                      label="Edad"
                      type="number"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xl="2" class="py-0">
                    <v-text-field
                      v-model="datos.ocupacion_garante"
                      label="Ocupación"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3"  xl="2" class="py-0">
                    <v-text-field
                      v-model="datos.calle_garante"
                      label="Calle"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1" class="py-0">
                    <v-text-field
                      v-model="datos.numero_garante"
                      label="Nº"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1" class="py-0">
                    <v-text-field
                      v-model="datos.piso_garante"
                      label="Piso"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1" class="py-0">
                    <v-text-field
                      v-model="datos.dep_garante"
                      label="Dep."
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.localidad_garante"
                      label="Localidad/Barrio"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.departamento_garante"
                      label="Departamento"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.provincia_garante"
                      label="Provincia"
                      :rules="datos.garante ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- garante 2 -->
            <v-card
              v-if="datos.empresa != 1 && datos.tipo_id == 2"
              style="width:100%"
              class="mx-3 mb-4"
              outlined
            >
              <v-card-title class="pt-1" style="font-size: 18px">
                Datos del segundo garante
              </v-card-title>
              <v-card-text class="py-1">
                <v-row>
                  <v-col cols="12" sm="6" md="4" xl="3" class="py-0">
                    <v-text-field
                      v-model="datos.garante2"
                      label="Nombre"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field-simplemask
                      v-model="datos.cuil_garante2"
                      label="CUIL"
                      v-bind:properties="{
                        rules: datos.garante2 ? [$store.state.rules.required] : [],
                        'validate-on-blur': true,
                        outlined: true,
                        dense: true
                      }"
                      v-bind:options="{
                        inputMask: '##-########-#',
                        outputMask: '##-########-#',
                        applyAfter: false,
                        alphanumeric: false,
                        empty: null
                      }"
                    />
                  </v-col>
                  <v-col cols="6" sm="3" md="2" xl="1" class="py-0">
                    <v-autocomplete
                      v-model="datos.estado_civil_garante2"
                      label="Estado Civil"
                      item-text="nombre"
                      item-value="nombre"
                      :items="tipos_est_civil"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="1" xl="1" class="py-0">
                    <v-text-field
                      v-model="datos.edad_garante2"
                      label="Edad"
                      type="number"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xl="2" class="py-0">
                    <v-text-field
                      v-model="datos.ocupacion_garante2"
                      label="Ocupación"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3"  xl="2" class="py-0">
                    <v-text-field
                      v-model="datos.calle_garante2"
                      label="Calle"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1" class="py-0">
                    <v-text-field
                      v-model="datos.numero_garante2"
                      label="Nº"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1" class="py-0">
                    <v-text-field
                      v-model="datos.piso_garante2"
                      label="Piso"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1" class="py-0">
                    <v-text-field
                      v-model="datos.dep_garante2"
                      label="Dep."
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.localidad_garante2"
                      label="Localidad/Barrio"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.departamento_garante2"
                      label="Departamento"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-0">
                    <v-text-field
                      v-model="datos.provincia_garante2"
                      label="Provincia"
                      :rules="datos.garante2 ? [$store.state.rules.required] : []"
                      validate-on-blur
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- FORMULARIO 03 -->
    <v-col
      v-if="selected.find(s => s == 2)"
      cols="12" md="6"
      class="pt-0"
    >
      <v-card>
        <v-card-title class="pt-1">
          Formulario 03
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form ref="form03" @submit.prevent="buscar()">
          <v-row>
            <v-col cols="6" md="5" xl="3" class="py-0">
              <FechaPicker
                v-model="datos.fecha_03"
                label="Fecha del contrato"
              />
            </v-col>
            <v-col cols="6" md="5" xl="4" class="py-0">
              <v-text-field-money
                v-model.trim="datos.monto_prenda"
                label="Monto"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  rules: [$store.state.rules.required],
                  'validate-on-blur': true,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
              />
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- BOTONES -->
    <v-col
      cols="12"
      :md="selected.find(s => s == 2) ? 6 : 12"
      class="d-flex justify-end align-center pt-0"
    >
      <v-btn-toggle
        v-model="selected"
        color="rojoDycar"
        :disabled="!existe"
        multiple
        rounded
      >
        <v-btn
          :value="1"
          :disabled="!existe"
        >
          <v-icon v-if="selected.find(s => s == 1)" small left>fas fa-check</v-icon>
          No rodado
        </v-btn>

        <v-btn
          :value="2"
          :disabled="!existe"
        >
          <v-icon v-if="selected.find(s => s == 2)" small left>fas fa-check</v-icon>
          03
        </v-btn>

        <v-btn
          :value="3"
          :disabled="!existe"
        >
          <v-icon v-if="selected.find(s => s == 3)" small left>fas fa-check</v-icon>
          Prenda
        </v-btn>
      </v-btn-toggle>
      <BtnConfirmar
        clase="ml-6"
        nombre="Descargar"
        icono="fas fa-download"
        :texto="`¿Desea descargar ${selected.length == 1 ? 'el' : `los ${selected.length}`} formulario${selected.length == 1 ? '' : 's'} seleccionado${selected.length == 1 ? '' : 's'}?`"
        :disabled="selected.length == 0"
        @action="descargar()"
      />
    </v-col>
    <!-- MODAL PARA AGREGAR NUEVOS USOS -->
    <v-dialog
      v-model="dialog_uso"
      width="300"
    >
      <v-card>
        <v-card-title>
          Nuevo uso
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_uso = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-form ref="formUso">
            <v-text-field
              v-model="uso"
              label="Uso"
              :rules="[$store.state.rules.required]"
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            @click="dialog_uso = false"
          >
            Cancelar
          </v-btn>
          <BtnConfirmar
            @action="nuevo_uso()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { formularioNoRodado, formulario03, formularioPrendaGPAT, formularioPrendaCitroenCirculo, formularioPrendaCitroenPSA, formularioSantander } from '../util/plantillas/pdfs'
import download from 'downloadjs'
import FechaPicker from '../components/util/FechaPicker'
import BtnConfirmar from '../components/util/BtnConfirmar'

export default {
  data () {
    return {
      existe: false,
      dialog_uso: false,
      uso: '',
      empresa: null,
      sucursal: {},
      tipo: {},
      financiera: {},
      valor_cuota: 0,
      preventa: '',
      selected: [],
      datos: {},
      datos_copy: {}
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('genericos/get_tipos_doc')
    await this.$store.dispatch('genericos/get_tipos_sexo')
    await this.$store.dispatch('genericos/get_sucursales')
    await this.$store.dispatch('genericos/get_financieras')
    await this.$store.dispatch('genericos/get_estados_civiles')
    await this.$store.dispatch('patentamiento/get_usos')
    await this.$store.dispatch('patentamiento/get_tipos')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['empresas', 'familias']),
    ...mapState('genericos', ['tipos_doc', 'tipos_sexo', 'sucursales', 'tipos_est_civil', 'financieras']),
    ...mapState('patentamiento', ['usos', 'tipos'])
  },
  components: {
    FechaPicker,
    BtnConfirmar
  },
  watch: {
    dialog_uso (val) {
      if (!val) {
        this.$refs.formUso.resetValidation()
        this.uso = ''
      }
    }
  },
  methods: {
    selectSucursal () {
      const sucursales = this.sucursales.filter(suc => suc.familia_id == this.empresa)
      if (sucursales.length == 1) this.sucursal = sucursales[0]
      else this.sucursal = {}
    },
    selectFinanciera () {
      this.limpiar()
      this.preventa = ''
      const financieras = this.financieras.filter(fin => fin.empresas.find(ef => ef == this.empresa) && fin.tipos.find(tf => tf == this.tipo.id))
      if (financieras.length == 1) this.financiera = financieras[0]
      else this.financiera = {}
    },
    async nuevo_uso () {
      if (this.$refs.formUso.validate()) {
        this.$store.state.loading = true
        await this.$store.dispatch('patentamiento/nuevo_uso', { nombre: this.uso })
          .then((res) => {
            this.dialog_uso = false
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    calcular_cuota () {
      if (this.datos.cuotas && this.datos.monto_prenda) {
        this.valor_cuota = this.datos.monto_prenda / this.datos.cuotas
        this.datos.valor_cuota = this.valor_cuota
      }
    },
    async descargar () {
      // valdia campos de los formularios
      if (!this.$refs.formCliente.validate()) return
      if (!this.$refs.formVehiculo.validate()) return
      if (this.selected.find(s => s == 2)) {
        if (!this.$refs.form03.validate()) {
          return
        }
      }
      if (this.selected.find(s => s == 3)) {
        if (this.$refs.formPrenda.validate()) {
          if (this.datos.tipo_id != 2 && !this.datos.fecha_vencimiento) {
            return this.$store.dispatch('show_snackbar', {
              text: 'Ingrese una fecha de vencimiento',
              color: 'warning'
            })
          }
        } else {
          return
        }
      }

      this.$store.state.loading = true
      await this.$store.dispatch('patentamiento/nuevo', this.datos)
        .then((res) => {
          this.datos = res.data
          this.datos.sucursal = JSON.parse(JSON.stringify(this.datos_copy.sucursal))
          this.datos.financiera = JSON.parse(JSON.stringify(this.datos_copy.financiera))
          this.datos_copy = JSON.parse(JSON.stringify(this.datos))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      // genera los formularios
      try {
        for (const valor of this.selected) {
          let file = null
          switch (valor) {
            case 1:
              file = await formularioNoRodado(this.datos)
              download(file, `No rodado ${this.datos.preventa}.pdf`, 'application/pdf')
              break
            case 2:
              file = await formulario03(this.datos)
              download(file, `03 ${this.datos.preventa}.pdf`, 'application/pdf')
              break
            case 3:
              if (this.datos.tipo_id == 2) {
                if (this.datos.empresa != 1) {
                  file = await formularioPrendaCitroenCirculo(this.datos)
                }
              } else {
                switch (this.datos.financiera.id) {
                  case 1:
                    file = await formularioPrendaGPAT(this.datos)
                    break
                  case 3:
                    file = await formularioSantander(this.datos)
                    break
                  case 4:
                    file = await formularioPrendaCitroenPSA(this.datos)
                    break
                  case 5:
                    //file = await formularioFrances(this.datos)
                    break
                }
              }
              if (file == null) {
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró un contrato de prenda disponible para la empresa y tipo seleccionados',
                  color: 'info'
                })
                break
              }
              if (this.datos.financiera.id == 3) {
                download(file.hoja1, `Prenda ${this.datos.preventa} (hoja 1).pdf`, 'application/pdf')
                download(file.hoja2, `Prenda ${this.datos.preventa} (hoja 2).pdf`, 'application/pdf')
                download(file.hoja3, `Prenda ${this.datos.preventa} (hoja 3).pdf`, 'application/pdf')
              }
              else if (this.datos.empresa != 1 && (this.datos.financiera.id == 1 || this.datos.financiera.id == 2)) {
                download(file.hoja1, `Prenda ${this.datos.preventa} (hoja 1).pdf`, 'application/pdf')
                download(file.hoja2, `Prenda ${this.datos.preventa} (hoja 2).pdf`, 'application/pdf')
              } else {
                download(file, `Prenda ${this.datos.preventa}.pdf`, 'application/pdf')
              }
              break
          }
        }
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.empresa) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
      }
      if (Object.keys(this.sucursal).length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una sucursal',
          color: 'warning'
        })
      }
      if (Object.keys(this.tipo).length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un tipo',
          color: 'warning'
        })
      }
      if (this.preventa == '') {
        return this.$store.dispatch('show_snackbar', {
          text: `Debe ingresar un${this.tipo.codigo == 'GR' ? ' Grupo/Orden' : 'a Preventa'}`,
          color: 'warning'
        })
      }
      if (Object.keys(this.financiera).length == 0 && !(this.empresa == 1 && this.tipo.id == 2)) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una financiera',
          color: 'warning'
        })
      }
      this.limpiar()
      this.$store.state.loading = true
      await this.$store.dispatch('patentamiento/get_datos', {
        tipo: this.tipo.id,
        empresa: this.empresa,
        preventa: `${this.tipo.codigo} ${this.preventa}`
      })
        .then((res) => {
          this.existe = true
          this.datos = res.data
          this.valor_cuota = this.datos.valor_cuota
          this.datos.sucursal = JSON.parse(JSON.stringify(this.sucursal))
          this.datos.financiera = JSON.parse(JSON.stringify(this.financiera))
          this.datos_copy = JSON.parse(JSON.stringify(this.datos))
        })
        .catch(error => {
          return this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false

      if (!this.datos.unidad_id) {
        this.$swal.fire({
          icon: 'warning',
          title: `No tiene una unidad asignada ¿Desea continuar?`,
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          showCancelButton: true,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        })
          .then((res) => {
            // si pone que no o hace click fuera del modal no hace nada
            if (!res.isConfirmed) {
              this.limpiar()
            }
          })
      }
    },
    limpiar () {
      this.$refs.formCliente.resetValidation()
      this.valor_cuota = 0
      this.selected = []
      this.datos = {}
      this.datos_copy = {}
      this.existe = false
    }
  }
}
</script>